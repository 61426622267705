.feeds {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  vertical-align: top;
  
  margin-left: 1em;

  overflow-x: scroll;
}

.feeds > .add-new {
  vertical-align: middle;
  margin: auto 2em auto 0;
}

