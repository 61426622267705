.feed > .header {
    display: flex;
    margin-bottom: 1em;
    padding: 1em;
}

.feed > .header > .actions {
    margin-left: auto;
}

.feed > .header > form > div.input {
    width: 31em;
}
