html, body {
  height: 100%;
  width: 100%;
}

body {
  background-color: #555 !important;
}

.root {
  height: 100%;
  width: 100%;
}
