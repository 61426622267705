.app {
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;
}

.app.view, .app.edit {
  grid-template-columns: 1fr 50em;
}

.app.view.edit {
  grid-template-columns: 1fr 50em 50em;
}

.app > .top-menu {
  grid-column: 1;
  grid-row: 1;
}

.app.view > .top-menu, .app.edit > .top-menu {
  grid-column: 1/3;
}

.app.view.edit > .top-menu {
  grid-column: 1/4;
}

.app > .feeds {
  grid-row: 2;
  grid-column: 1;
}

.app.view > .note.view {
  grid-row: 2;
  grid-column: 2;
  width: 50em;
}

.app.edit > .note.edit {
  grid-row: 2;
  grid-column: 2;
  width: 50em;
}

.app.view.edit > .note.edit {
  grid-row: 2;
  grid-column: 3;
  width: 50em;
}
