.feed {
  width: 37em;
  min-width: 37em;
  margin-right: 1em;

  background-color: #999;
  border-radius: 6px;
}

.feed > .body {
  overflow-y: scroll;
  padding: 1em;
}
