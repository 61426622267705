.ContactCard .header {
    font-size: 1em !important;
}

.ContactRow > td > button {
    visibility: hidden;
}

.ContactRow:hover > td > button {
    visibility: visible;
}

.ContactRow td.actions {
    text-align: right;
}