.ui.menu .my-tasks.ui.dropdown .menu {
    overflow-y: scroll;
}

.ui.menu .my-tasks.ui.dropdown .menu > .header {
    background: rgb(220, 220, 220);
    padding: 1rem 1.14285714rem 0.75rem;
}

.ui.menu .my-tasks.ui.dropdown .menu > .header.note {
    background: transparent;
    margin: 0;
    padding: 0.5rem 1.14285714rem 0.5rem 1.64285714rem ;
    text-transform: none;
    font-size: 1em;
}

.ui.menu .my-tasks.ui.dropdown .menu > .header.note .ui.compact.icon.button {
    padding: 0.3em;
    margin: 0em 0.3em;
}

.ui.menu .my-tasks.ui.dropdown .menu > .item {
    padding: 0.78571429em 2.04285714em !important;
}

.ui.menu .my-tasks.ui.dropdown .menu > .item.task {
    display: flex;
    justify-content: space-between;
}

.ui.menu .my-tasks.ui.dropdown .menu > .item.task .ui.checkbox {
    display: block;
}

.ui.menu .my-tasks.ui.dropdown .menu > .item .date {
    display: block;
    font: bold small sans-serif;
    color: lightslategray;
    line-height: 17px;
    margin-left: 0.5em;
}

.ui.menu .my-tasks.ui.dropdown .menu > .item.task.complete label {
    font-style: italic;
    color: darkgray;
    text-decoration: line-through;
}

.ui.menu .my-tasks.ui.dropdown .menu > .item.task.complete .date {
    font-style: italic;
    color: darkgray;
    text-decoration: line-through;
}
