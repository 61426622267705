.ContactsModal > .header {
    display: flex !important;
    justify-content: space-between;
    padding-right: 1rem !important;
}

.ContactsModal > .header .actions {
    display: inline-block;
    margin-left: 1em;
}

.ContactsModal > .header .ui.form {
    display: inline-block;
}


.ContactsModal > .header input {
    font-size: 1rem;
}
