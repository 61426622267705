.note.preview {
  /* border: 2px solid darkgrey;  */
  border-radius: 6px;
  background-color: white;
  padding: 0.5em;
  margin-bottom: 2em;
  cursor: pointer;
}

.note.preview > .body {
  /*height: 20em;*/
  overflow: hidden;
}

.small.note.preview > .body {
  height: 12em;
}
.medium.note.preview > .body {
  height: 25em;
}
.large.note.preview > .body {
  height: 40em;
}

.note.preview > .footer {
  display: flex;
}

.note.preview > .footer > .actions {
  min-width: 6.5em;
  margin-left: auto;
  margin-top: auto;
}

.note.preview > .footer > .tags .ui.basic.button {
  padding: 0.25em 1.5em;
  margin: 0.25em;
}

.note.preview .task-list-item.complete {
  font-style: italic;
  color: darkgray;
  text-decoration: line-through;
}

.note.preview pre {
  background-color: #ddd;
  padding: 0.2em;
  border-radius: 3px;
}
