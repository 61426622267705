.note.view {
  background-color: white;
  padding: 1em;
  border-radius: 6px;
  
}
.note.view > .body {
  overflow-y: scroll;

  font-size: 16px;
}

.note.view > .body h1 {
  font-size: 2em;
}

.note.view > .body h2 {
  font-size: 1.7143em;
}

.note.view > .body h3 {
  font-size: 1.2857em;
}

.note.view > .body h4 {
  font-size: 1.0714em;
}

.note.view > .body blockquote {
  margin-left: 0;
  padding-left: 1em;
  border-left: 0.4em solid lightgray;
}

.note.view .header {
  display: flex;
  flex-direction: row;
}


.note.view .date {
  flex: 1;
}

.note.view .actions {
  /*horiz-align: right;*/
}

.note.view .task-list-item.complete {
  font-style: italic;
  color: darkgray;
  text-decoration: line-through;
}

.note.view pre {
  background-color: #ddd;
  padding: 0.2em;
  border-radius: 3px;
}

.note.view pre code {
  color: black;
}

.note.view code {
  font-size: 0.9em;
  color: darkblue;
  background-color: #ddd;
}
