.note > .header .title {
  font: bold xx-large sans-serif;
}

.note > .header > .date {
  font: bold small sans-serif;
  color: lightslategray;
  margin-bottom: 0.5em;
}

.mention {
  background-color: #dddddd;
  color: black;
  border-radius: 4px;
  padding: 0.1em 0.2em;

  display: inline-block;
  border-bottom: 1px dotted black;
}

.mention.ui.dropdown .menu>.item {
  padding: 0.2em !important;
}

.mention .ui.button {
  margin: 0;
}