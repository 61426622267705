.note.edit {
  background-color: lightgray;
  padding: 1em;
  border-radius: 6px;
  
}

.note.edit .editor {
  border-radius: 6px;
}

.note.edit .body {
  margin: 1em 0em;
}

.note.edit .actions {
  text-align: right;
}

.note.edit .edit-status {
  font: 1em sans-serif;
  color: #555555;
  margin-right: 1em;
}

.note.edit .edit-status.error {
  color: #550000;
}
